import React from 'react'
import { Link, navigate } from 'gatsby'
import { Layout } from '../components'
import { Login } from '../components/authForms'
import { useApp } from '../context/appContext'
import '../components/authForms/authForms.scss'

const Auth = () => {
  const { isAuthenticated } = useApp()

  if (isAuthenticated()) {
    navigate('/user/dashboard')
  }

  return (
    <Layout>
      <div className="section-login section-inner section-inner--xs vertical-indent--m">
        <h2>Вход</h2>
        <Login />
        <p className="section-login__no-account">
          У Вас еще нет аккаунта? <br /> <Link to="/register">Перейти к странице регистрации.</Link>
        </p>
      </div>
    </Layout>
  )
}

export default Auth
